<template>
  <div>
    <div class="media-list-wrap">
      <Header :active="activeNav" />
      <Kv />
      <div class="list-top">
        <div class="center">
          <div class="slogan"></div>
          <div :class="['list-tab', showList === 0 ? 'active0' : 'active1']" v-if="imgData.list.length > 0 && videoData.list.length > 0">
            <a href="javascript:;" @click="changeList(0)">图片</a>
            <a href="javascript:;" @click="changeList(1)">视频</a>
          </div>
        </div>
      </div>
      <div class="list-box center" v-if="imgData.list.length > 0 || videoData.list.length > 0">
        <div class="list">
          <div class="list-item" v-for="(item, index) in  showList === 0 ? imgData.list : videoData.list" :key="index" @click="showDia(item)">
            <img v-if="showList === 0" :src="`${api}${item.advertisementArr[0].advertisement_img}`" :alt="item.title" />
            <img v-if="showList === 1" :src="`${api}${item.cover_img}`" :alt="item.title" />
            <span v-if="showList === 1" class="icon-play"></span>
          </div>
        </div>
        <ul v-if="showList === 0" class="page-turner-contain">
          <li @click="prevPage(imgData, 'img')" :class="imgData.params.page === 1 ? 'disabled' : null">上一页</li>
<!--          <li v-for="(item, index) in imgData.last_page" :key="index" @click="clickPage(index, imgData, 'img')" :class="index+1 ===  imgData.params.page ? 'active' : ''">{{item}}</li>-->
          <li @click="nextPage(imgData, 'img')" :class="imgData.params.page === imgData.last_page ? 'disabled' : null">下一页</li>
        </ul>
        <ul v-if="showList === 1" class="page-turner-contain">
          <li @click="prevPage(videoData, 'video')" :class="videoData.params.page === 1 ? 'disabled' : null">上一页</li>
<!--          <li v-for="(item, index) in videoData.last_page" :key="index" @click="clickPage(index, videoData, 'video')" :class="index+1 === videoData.params.page ? 'active' : ''">{{item}}</li>-->
          <li @click="nextPage(videoData, 'video')" :class="videoData.params.page === videoData.last_page ? 'disabled' : null">下一页</li>
        </ul>
      </div>
      <a href="javascript:;" class="icon-top" v-if="false"></a>

    </div>
    <Footer />
    <div class="dialog" id="dialog" v-if="showDialog" :style="{width: `${width}px`, left: '50%', marginLeft: `-${width/2}px`}">
      <div class="close">
        <a href="javascript:;" class="icon-close" @click="closeDia"></a>
      </div>
      <img :src="showImg" alt="" v-if="showList === 0"/>
      <div class="videoBox" v-if="showList === 1">
        <video id="video" :src="showVideo" autoplay controls></video>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/common/Header";
import Kv from "../components/common/Kv";
import Footer from "../components/common/footerBat"
import { fetchPost } from "@/api/https"


export default {
  name: 'MediaList',
  components: {
    Header,
    Kv,
    Footer
  },
  data(){
    return {
      width: null,
      api: 'https://admin.ilogin.com.cn/',
      activeNav: 3,
      showList:0,
      showImg: '',
      showVideo: '',
      showDialog: false,
      imgData:{
        list: [],
        params:{
          page:1,
          perpage:3
        },
        total: Number,
        last_page: Number
      },
      videoData:{
        list: [],
        params:{
          page:1,
          perpage:3
        },
        total: Number,
        last_page: Number
      },
    }
  },
  methods:{
    changeList(i) {
      this.showList = i;
    },
    openDetails(id){
      let routeUrl =this.$router.resolve({path: '/NewsDetails', query: {  newsId: id }})
      window.open(routeUrl.href, '_blank');
    },
    nextPage(data, type){
      let page = data.params.page;
      if(page === data.last_page) return false;
      data.params.page = page + 1;
      if (type === 'img') {
        this.handleGetImgList()
      } else {
        this.handleGetVideoList()
      }
    },
    prevPage(data, type){
      let page = data.params.page;
      if(page === 1) return false;
      data.params.page = page - 1;
      if (type === 'img') {
        this.handleGetImgList()
      } else {
        this.handleGetVideoList()
      }
    },
    clickPage(i, data, type){
      if(i+1 === data.params.page){
        return false;
      } else {
        data.params.page = (i + 1);
      }
      if (type === 'img') {
        this.handleGetImgList()
      } else {
        this.handleGetVideoList()
      }
    },
    handleGetImgList(){
      fetchPost('adVideoImg', this.imgData.params).then(response => {
        this.imgData.list = response.data.data.data;
        this.imgData.last_page = response.data.data.last_page;
        this.imgData.total = response.data.data.total;
        setTimeout(()=>{

        }, 300)
      })
    },
    handleGetVideoList(){
      fetchPost('videoList', this.videoData.params).then(response => {
        this.videoData.list = response.data.data.data;
        this.videoData.last_page = response.data.data.last_page;
        this.videoData.total = response.data.data.total;
        setTimeout(()=>{

        }, 300)
      })
    },
    showDia(item) {
      this.width = document.body.clientWidth;
      if (this.showList === 0) {
        this.showImg = `${this.api}${item.advertisementArr[0].advertisement_img}`
      } else {
        this.showVideo = `${this.api}${item.video[0].video_url}`
      }
      this.showDialog = true
    },
    closeDia(){
      this.showImg = ''
      this.showVideo = ''
      this.showDialog = false
    }
  },
  mounted() {
    this.handleGetImgList()
    this.handleGetVideoList()
    this.$nextTick(() =>{
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .media-list-wrap{
    width:100%;
    background: linear-gradient(0deg, #FFFFFF 0%, #FFFBED 100%);
    padding-bottom:216px;
  }

  .center{
    width:1580px;
    height:100%;
    margin:0 auto;
  }

  .list-top{
    height:140px;
    border-bottom:2px dashed #666;
    margin-bottom:52px;
  }

  .list-top .center{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .list-top .slogan{
    width:421px;
    height:103px;
    background: url("../assets/images/mediaList/slogan.png") no-repeat center/contain;
    margin-right:56px;
  }

  .list-top .list-tab{
    width:261px;
    height:68px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list-top .list-tab.active0{
    background: url("../assets/images/mediaList/bg-tab1.png") no-repeat center/cover;
  }

  .list-top .list-tab.active1{
    background: url("../assets/images/mediaList/bg-tab2.png") no-repeat center/cover;
  }

  .list-top .list-tab a{
    width:100%;
    height:100%;
    text-indent: -9999em;
  }

  .list-box .list{
    width:100%;
    height:838px;
    margin-bottom:95px;
  }

  .list .list-item{
    width:780px;
    height:414px;
    border: 2px solid #FEF1C3;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    float: right;
  }

  .list .list-item:first-child{
    height:100%;
    width:780px;
    margin-right:auto;
    float: left;
  }

  .list .list-item:nth-child(2){
    margin-bottom:10px;
  }

  .list .list-item img{
    min-width:100%;
    min-height:100%;
    display: block;
  }

  .list .list-item:nth-child(2) img,
  .list .list-item:nth-child(3) img{
    position: absolute;
    top:50%;
    left:50%;
    margin-left:-50%;
    margin-top:-50%;
  }


  .list .list-item::before{
    content: '';
    width: 754px;
    height: 388px;
    border: 1px solid #EDC025;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 5;
    opacity: 0;
  }

  .list .list-item:first-child::before{
    width: 754px;
    height: 814px;
  }

  .list .list-item .icon-play{
    width:151px;
    height:151px;
    background: url("../assets/images/mediaList/icon-paly.png") no-repeat center/contain;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 10;
  }

  .list .list-item:hover img{
    transform: scale(1.1);
    filter:brightness(1.1);
    -webkit-filter:brightness(1.1);
  }

  .list .list-item:hover::before{
    opacity: 1;
  }

</style>
