<template>
  <div>
    <div class="news-list-wrap">
      <Header :active="activeNav" />
      <Kv />
      <div class="list-top">
        <div class="center">
          <div class="slogan"></div>
          <div class="list-tab">
            <a href="javascript:;" :class="showList === 0 ? 'active' : ''" @click="changeList(0)">新闻</a>
            <span>/</span>
            <a href="javascript:;" :class="showList === 1 ? 'active' : ''" @click="changeList(1)">公告</a>
          </div>
        </div>
      </div>
      <div class="list-box center">
        <ul class="list">
          <li class="list-item" v-for="(item, index) in  showList === 0 ? newsData.list : noticeData.list" :key="index" @click="openDetails(item.id)">
            <div class="cover">
              <img :src="`${api}${item.cover_img}`" alt="新闻配图" />
            </div>
            <div class="info">
              <h2 class="time">{{item.release_time | strReplace}}</h2>
              <h1 class="title">
                <span class="label">[{{showList === 0 ? '新闻' : '公告'}}]</span>
                {{item.title}}
              </h1>
              <p class="des">{{item.content | componentFilter}}</p>
            </div>
          </li>
        </ul>
      </div>
      <ul v-if="showList === 0 && newsData.params.page < newsData.last_page" class="page-turner-contain">
        <li @click="prevPage(newsData, 'news')" :class="newsData.params.page === 1 ? 'disabled' : null">上一页</li>
<!--        <li v-for="(item, index) in newsData.last_page" :key="index" @click="clickPage(index, newsData, 'news')" :class="index+1 ===  newsData.params.page ? 'active' : ''">{{item}}</li>-->
        <li @click="nextPage(newsData, 'news')" :class="newsData.params.page === newsData.last_page ? 'disabled' : null">下一页</li>
      </ul>
      <ul v-if="showList === 1 && noticeData.params.page < noticeData.last_page" class="page-turner-contain">
        <li @click="prevPage(noticeData, 'notice')" :class="noticeData.params.page === 1 ? 'disabled' : null">上一页</li>
<!--        <li v-for="(item, index) in noticeData.last_page" :key="index" @click="clickPage(index, noticeData, 'notice')" :class="index+1 === noticeData.params.page ? 'active' : ''">{{item}}</li>-->
        <li @click="nextPage(noticeData, 'notice')" :class="noticeData.params.page === noticeData.last_page ? 'disabled' : null">下一页</li>
      </ul>
      <a href="javascript:;" class="icon-top" v-if="false"></a>
    </div>
    <Footer />
  </div>

</template>

<script>
import Header from "../components/common/Header";
import Kv from "../components/common/Kv";
import Footer from "../components/common/footerBat"
import {fetchPost} from "@/api/https"


export default {
  name: 'NewsList',
  components: {
    Header,
    Kv,
    Footer,
  },
  data(){
    return {
      api: 'https://admin.ilogin.com.cn/',
      activeNav: 1,
      showList:0,
      //新闻
      newsData:{
        list: [],
        params:{
          page:1,
          perpage:1
        },
        total: Number,
        last_page: Number
      },
      //公告
      noticeData:{
        list: [],
        params:{
          page:1,
          perpage:10
        },
        total: Number,
        last_page: Number
      },
    }
  },
  filters:{

    componentFilter:function(value){
      value = value.replace(/<[^<>]+>/g,'')
      value = value.replace(/[\'\"\\\/\b\f\n\r\t\'&ldquo;'\'nbsp'\'&rdquo;'\']/g, '') // eslint-disable-line
      return value
    },
    strReplace:function (time){
      return /\d{4}-\d{1,2}-\d{1,2}/g.exec(time)[0]
    }
  },
  methods:{
    changeList(i) {
      this.showList = i;
    },
    openDetails(id){
      let routeUrl =this.$router.resolve({path: '/NewsDetails', query: {  newsId: id }})
      window.open(routeUrl.href, '_blank');
    },
    handleGetNewsList(){
      fetchPost('imgTextNews', this.newsData.params).then(response => {
        this.newsData.list = response.data.data.data
        this.newsData.last_page = response.data.data.last_page;
        this.newsData.total = response.data.data.total;
      })
    },
    handleGetNoticeList(){
      fetchPost('imgTextActive', this.noticeData.params).then(response => {
        this.noticeData.list = response.data.data.data
        this.noticeData.last_page = response.data.data.last_page;
        this.noticeData.total = response.data.data.total;
      })
    },
    nextPage(data, type){
      let page = data.params.page;
      if(page === data.last_page) return false;
      data.params.page = page + 1;
      if (type === 'news') {
        this.handleGetNewsList()
      } else {
        this.handleGetNoticeList()
      }
    },
    prevPage(data, type){
      let page = data.params.page;
      if(page === 1) return false;
      data.params.page = page - 1;
      if (type === 'news') {
        this.handleGetNewsList()
      } else {
        this.handleGetNoticeList()
      }
    },
    clickPage(i, data, type){
      if(i+1 === data.params.page){
        return false;
      } else {
        data.params.page = (i + 1);
      }
      if (type === 'news') {
        this.handleGetNewsList()
      } else {
        this.handleGetNoticeList()
      }
    },
  },
  mounted() {
    this.handleGetNewsList()
    this.handleGetNoticeList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .news-list-wrap{
    width:100%;
    background: linear-gradient(0deg, #FFFFFF 0%, #FFFBED 100%);
    padding-bottom:216px;
  }

  .center{
    width:1512px;
    height:100%;
    margin:0 auto;
  }

  .list-top{
    height:140px;
    border-bottom:2px dashed #666;
    margin-bottom:56px;
  }

  .list-top .center{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .list-top .slogan{
    width:421px;
    height:103px;
    background: url("../assets/images/newsList/slogan.png") no-repeat center/contain;
    margin-right:80px;
  }

  .list-top .list-tab a{
    font-size: 40px;
    color: #666666;
    padding:10px 6px;
    border-bottom:3px solid transparent;
    font-weight: bold;
  }

  .list-top .list-tab a.active{
    border-bottom-color:#FBEB00;
    color:#FBEB00;
  }

  .list-tab span{
    font-size:30px;
    color:#666;
    margin:0 60px;
  }

  .list{
    margin-bottom:100px;
  }

  .list .list-item{
    height: 286px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom:42px;
  }

  .list-item .cover{
    width:538px;
    height:286px;
    border: 2px solid #FEF1C3;
    overflow: hidden;
    position: relative;
  }

  .list-item .cover img{
    display: block;
    width:100%;
    height:100%;
  }

  .list-item .cover::before{
    content: '';
    width:0;
    height:0;
    opacity: 0;
    border: 1px solid #FEF1C3;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 5;
  }

  .list-item:hover .cover::before{
    width:520px;
    height:268px;
    opacity: 1;
  }

  .list-item:hover img{
    transform: scale(1.1);
    filter:brightness(1.1);
    -webkit-filter:brightness(1.1);
  }

  .list-item .info{
    width:918px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
  }

  .info .time{
    font-size: 38px;
    font-weight: 600;
    color: #666;
    line-height: 70px;
  }

  .info .title{
    font-size: 36px;
    font-weight: 600;
    color: #666;
    line-height: 70px;
    width:100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }

  .info .title .label{
    color: #FBEB00;
  }

  .info .des{
    font-size: 30px;
    font-weight: 300;
    color: #666;
    line-height: 40px;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    overflow:hidden;
  }

  .news-list li:hover .des{
    color: #FBEB00;
  }

</style>
