<template>
  <div>
    <div class="news-details">
      <Header :active="activeNav" />
      <Kv />
      <div class="detail detail1">
        <div class="details-top">
          <h1 class="title">{{detailsData.title}}</h1>
          <h6 class="time">{{detailsData.release_time}}</h6>
        </div>
        <div class="details-main" v-html="detailsData.content" />
      </div>
      <a href="javascript:;" class="icon-top" v-if="false"></a>
    </div>
    <Footer />
  </div>

</template>

<script>
import Header from "../components/common/Header";
import Kv from "../components/common/Kv";
import Footer from "../components/common/footerBat"
import { fetchPost } from "@/api/https"


export default {
  name: "NewsDetails",
  components: {
    Header,
    Kv,
    Footer
  },
  data(){
    return{
      id: '',
      activeNav: 1,
      detailsData: {}
    }
  },
  methods:{
    handleGetDetails(){
      const params = {id: this.id}
      fetchPost('imgTextDetail', params).then(response => {
        this.detailsData = response.data.data
      })
    }
  },
  mounted() {
    this.$nextTick(() =>{
      this.id = Number(this.$route.query.newsId)
      this.handleGetDetails()
    })
  }
}
</script>

<style scoped>
  .news-details{
    width:100%;
    background: linear-gradient(0deg, #FFFFFF 0%, #FFFBED 100%);
    padding-bottom:216px;
    position: relative;
  }

  .details-top{
    height:238px;
    border-bottom:1px dashed #666;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
  }

  .details-top .title{
    width:100%;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #333333;
    line-height: 52px;
  }

  .details-top .time{
    width:100%;
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    color: #666666;
    line-height: 40px;
  }

  .details-main{
    padding:110px 285px;
    font-size: 23px;
    font-weight: 600;
    color: #333333;
    line-height: 30px;
  }

  .details-main img{
    display: block;
    margin:10px auto;
  }

</style>
