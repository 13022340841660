<template>
  <div class="kv" :style="{ backgroundImage: `url( ${bg} )` }"></div>
</template>

<script>
import { fetchPost } from "@/api/https"

export default {
  name: "Kv",
  data() {
    return {
      bg: ''
    }
  },
  mounted() {
    this.getBG()
  },
  methods:{
    getBG(){
      fetchPost('baseInfo').then(response => {
        this.bg = 'https://admin.ilogin.com.cn/' + response?.data?.data[1]?.optionvalue
      })
    }
  }
}
</script>

<style scoped>
  .kv{
    width:100%;
    height:727px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 727px;
    /*background: url("../../assets/images/newsList/kv.jpg") no-repeat center/cover;*/
  }
</style>
